.t-home{
    #splash-wrap{
        min-height: 100vh;
    }
    
}
.t-contact{
    .inquiry-form{
        background-color: #EDEDED;
    }
}
.t-service{
    .container-top{
        .title, .summary{
            background-color: $green;
            position: relative;
            margin-bottom: 0;
            &::before, &::after {
                position: absolute;
                width: 100vw;
                content: "";
                background-color: #054843;
                top:0;
                bottom:0;
            }
            &::before{
                right:100%;
            }
            &::after{
                left:100%;
            }
        }
        .summary{
            padding-bottom: 3rem;
        }
    }
    .splide__pagination{
        display: none;
    }

    @include media-breakpoint-down(md){
        .vw50{
            width: 100%;
        }
    }
}
.variant-container{
    display: none;
    &.show{
        display: block;
    }
}