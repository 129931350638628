// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables 
// @import "../../node_modules/bootstrap/scss/_variables.scss";

// Theme colors

$enable-negative-margins: true; 

$green: #054843;
$medium-green:#00AD8B;
$leaf:#1A8077;
$light-green: #2ED6B4;
$orange: #D68317;
$black: #2E2E2E;
$white: #FCFCFC;
$yellow: #EBAE49;
$primary: $green;
$secondary: $light-green;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "green": $green,
  "medium-green": $medium-green,
  "light-green": $light-green,
  "orange": $orange,
  "black": $black,
  "white": $white,
  "yellow": $yellow,
  "leaf": $leaf,
);

// Fonts
$font-primary: montserrat, sans-serif;
$font-secondary: source-sans-pro, sans-serif;

$font-family-base: $font-primary;
$font-size-base: 1rem;
$font-size-root: 18px;

$heading-font: "Titillium Web", sans-serif;
$headings-color: $black;
// Links
$link-color: #26658d;
$link-decoration: none;

// Navbar
$navbar-light-icon-color: $black;
$navbar-light-color:$black;
$navbar-light-active-color:$black;
$navbar-light-hover-color: $black;

// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

// Font size

$h1-font-size: 2.222rem;//40px
$h2-font-size: 1.667rem;//30px
$h3-font-size: 1.556rem;//28px
$h4-font-size: 1.333rem;   //24px
$h5-font-size: 1.2222rem;  //22px
$h6-font-size: 1.111rem;   //20px

$small-font-size: 0.944rem;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1363px
);

$offcanvas-padding-y: 0;
$offcanvas-padding-x: 0;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 9,
);
//Pagination
$accordion-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-button-hover-bg: transparent;

$accordion-button-active-color: $green;
$accordion-icon-active-color: $green;
$accordion-icon-transform:rotate(90deg);
$accordion-button-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="15" viewBox="0 0 18 15"><defs><clipPath id="a"><rect width="18" height="15" fill="#393b3c"/></clipPath></defs><g clip-path="url(#a)"><path d="M17.623,6.805a1.285,1.285,0,0,1,0,1.818l-6.429,6.429a1.286,1.286,0,0,1-1.818-1.818L13.611,9H1.286a1.286,1.286,0,1,1,0-2.571H13.611L9.377,2.194A1.286,1.286,0,0,1,11.195.376Z" fill="#{$black}"/></g></svg>');
$accordion-button-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="15" viewBox="0 0 18 15"><defs><clipPath id="a"><rect width="18" height="15" fill="#393b3c"/></clipPath></defs><g clip-path="url(#a)"><path d="M17.623,6.805a1.285,1.285,0,0,1,0,1.818l-6.429,6.429a1.286,1.286,0,0,1-1.818-1.818L13.611,9H1.286a1.286,1.286,0,1,1,0-2.571H13.611L9.377,2.194A1.286,1.286,0,0,1,11.195.376Z" fill="#{$accordion-icon-active-color}"/></g></svg>');

$btn-font-size:0.944rem;
$btn-border-radius:5px;
$btn-font-family:$font-secondary;
$navbar-light-icon-color: $white;
$btn-close-color: $white;